export const ROOT = {
	name: "rootPath",
	path: "/",
};
export const PROJECT = {
	name: "projectPath",
	path: "project",
};

export const dynamicURL = (url) => `https://${url}.mxnxn.com`

export const PROJECTS = Object.freeze([
	{ name: "PDF Generator", url: `${PROJECT.path}2` }, // done
	{ name: "React Drag and Drop Example", url: `${PROJECT.path}3` }, // done
	{ name: "Event Management ERP", url: `${PROJECT.path}4` }, // done
	{ name: "Web Landing Page", url: `${PROJECT.path}5` }, // done
	{ name: "Advertising Hoardings ERP", url: `${PROJECT.path}6` }, // api url needs to change @ frontend
]);

export const ROUTES = Object.freeze({
	rootPath: ROOT,
	projects: PROJECTS,
});
