import gsap from "gsap";
import { Power3 } from "gsap";
import Navbar from "Navbar/Navbar";
import React, { useEffect } from "react";

import BottomSection from "./BottomSection";

const Services = (props) => {
    useEffect(() => {
        gsap.from(".navbar", { y: 15, opacity: 0, ease: Power3.easeOut, delay: 0.15 }, 0.3);
    }, []);

    return (
        <>
            <Navbar />
            <div className="container flex-col overflowy">
                <div className="services-heading">
                    <div>Skills</div>
                    <div className="child-2">& Hands-on Experience</div>
                </div>
                <BottomSection />
            </div>
        </>
    );
};

export default Services;
