import React from "react";
import Breadcrums from "Breadcrums/Breadcrums";
import Heading from "Heading";
import Navbar from "Navbar/Navbar";

const LandingPage = (props) => {
  return (
    <>
      <Navbar />
      <div className="center-section">
        <div className="left-section">
          <Breadcrums />
          <Heading />
        </div>
        <div className="right-section">
          <div className="img-snippet" style={{zIndex:1}}>
            <img src={require("./assets/carbon1.png")} alt="snippets"></img>
          </div>
          {/* <div className="description">
          <div className="desc1">
            <span style={{ color: "#0ED200" }}>root@mxnxn:-$ </span>
            <span style={{ color: "#ffffff" }}>npm i && npm start</span>
            <br />
            <span style={{ color: "#ffffff" }}>. . .</span>
            <br />
            <span style={{ color: "#ffffff", display: "flex" }}>
              Local:{" "}
              <span style={{ marginLeft: "auto",paddingRight:"10%" }}>
                http://localhost:
                <span style={{ fontWeight: "bold" }}>3000</span>
              </span>
            </span>
            <span style={{ color: "#ffffff", display: "flex" }}>
              {" "}
              On Your Network:{" "}
              <span style={{ marginLeft: "auto",paddingRight:"10%" }}>
                http://10.0.0.69:
                <span style={{ fontWeight: "bold" }}>
                  3000
                </span>
              </span>{" "}
            </span>
            <span style={{ color: "#ffffff", display: "flex" }}>. . .</span>
            <span style={{ color: "#0ED200" }}>root@mxnxn:-$ </span>
          </div>
        </div> */}
        </div>
		
        <div className="explore-text">DESIGNER</div>
				<div className="explore-text2">DEVELOPER</div>

        {/* <Illustration /> */}
      </div>
	  
    </>
  );
};

export default LandingPage;
